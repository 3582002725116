.user-card {
  margin: 0 auto;
  float: none;
  margin-bottom: 25px;
}

.confirm-card {
  margin: 0 auto;
  float: none;
  margin-top: 5%;
  width: 65%;
}

.alert-container {
  position: absolute;
  top: 1rem;
  left: 20%;
  width: 60%;
}

@media (min-width: 768px) {
  .word-nowrap-sm {
    white-space: pre-line!important;;
  }
}

@media (max-width: 768px) {
  .word-nowrap-sm {
    white-space: nowrap!important;;
  }
}
